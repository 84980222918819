import React from "react";
import classes from "./header.module.css";
// import imgDental from "../../../assets/imgs/Dental Cabinet.png";
import { useTranslation } from "react-i18next";

const Header = () => {
  const [t, i18n] = useTranslation();

  return (
    <div className={classes.bg}>
      <header className={classes.header}>
        <h1>{t("homeHeader_h1")}</h1>
      </header>
      <div className={classes.imgContainer}>
        {/*<img src={imgDental} alt="Dental Secrets" />*/}
      </div>
    </div>
  );
};

export default Header;
