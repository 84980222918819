import React from "react";
import classes from "./aboutUs.module.css";
import DrKhaledImg from "../../assets/imgs/Dr khaled Elkordy test/6.jpeg";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

const languages = [
  {
    code: "en",
    name: "English",
    Country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    Country_code: "sa",
    // dir: "rtl",
  },
];

const AboutUs = () => {
  const currentLangsCode = cookies.get("i18next") || "en";
  const currentLangs = languages.find((l) => l.code === currentLangsCode);

  const [t, i18n] = useTranslation();

  return (
    <div
      className={classes.bg}
      style={{
        direction: `${currentLangs.name === "English" ? "ltr" : "rtl"}`,
      }}
    >
      <header className={classes.header}>
        <div className={classes.aboutcontent}>
          <p>{t("dentalCabinetFirstParagraph")}</p>
        </div>
        <Row className="flex-md-row flex-column-reverse">
          <Col lg={6}>
            <div className={classes.featuresContent}>
              <h3>{t("features_h3")}</h3>
              <p>{t("features_p")}</p>
            </div>
            <div className={classes.advantagesContent}>
              <h3>{t("Advantages_h3")}</h3>
              <h6>{t("Advantages_h6")}</h6>
              <div className={classes.ulcontainer}>
                <ul>
                  <li>{t("Advantages_li_1")}</li>
                  <li>{t("Advantages_li_2")}</li>
                  <li>{t("Advantages_li_3")}</li>
                  <li>{t("Advantages_li_4")}</li>
                </ul>
              </div>
            </div>
            <p className={classes.testP}>{t("AdvantagesTest_p")}</p>
          </Col>
          <Col lg={6}>
            <div className={classes.imgContainer}>
              <img src={DrKhaledImg} alt="" />
            </div>
          </Col>
        </Row>
      </header>
    </div>
  );
};

export default AboutUs;
