import React, { useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import Slider from "react-slick";
import { Container } from "react-bootstrap";

import "../cleopatra/cleopatra.css";
import classes from "../cleopatra/cleopatra.module.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link } from "react-router-dom";

import c1 from "../../../assets/imgs/Dr Ahmed Essam test/1.jpeg";
import c2 from "../../../assets/imgs/Dr Ahmed Essam test/2.jpeg";
import c3 from "../../../assets/imgs/Dr Ahmed Essam test/3.jpeg";
import c4 from "../../../assets/imgs/Dr Ahmed Essam test/4.jpeg";
import c5 from "../../../assets/imgs/Dr Ahmed Essam test/5.jpeg";
import c6 from "../../../assets/imgs/Dr Ahmed Essam test/6.jpeg";
import c7 from "../../../assets/imgs/Dr Ahmed Essam test/7.jpeg";
import c8 from "../../../assets/imgs/Dr Ahmed Essam test/8.jpeg";
import c9 from "../../../assets/imgs/Dr Ahmed Essam test/9.jpeg";
// import c10 from "../../../assets/imgs/Doctors gallery/Dr Ahmed Essam/10.jpg";

const imgs = [c1, c2, c3, c4, c5, c6, c7, c8, c9];

const DrAhmedEssam = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: (current, next) => setSlideIndex(next),
    centerMode: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={classes.cleopatra}>
      <div className={classes.closeButton}>
        <Link to="/gallery">
          <MdOutlineClose />
        </Link>
      </div>
      <Container>
        <h2>Dr Ahmed Essam</h2>
        <div className="slider">
          <Slider {...settings}>
            {imgs.map((img, index) => (
              <div
                key={index}
                className={
                  index === slideIndex ? "slide slide-active" : "slide"
                }
              >
                <img src={img} alt="" />
              </div>
            ))}
          </Slider>
        </div>
      </Container>
    </div>
  );
};

export default DrAhmedEssam;
