import React, { Component } from "react";
import Slider from "react-slick";
import "./gallery.css";
import classes from "./gallery.module.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { BsImageFill } from "react-icons/bs";

import cleopatraImg from "../../../assets/imgs/Cleopatra test/1.jpeg";
import DrAhmedEssamImg from "../../../assets/imgs/Dr Ahmed Essam test/6.jpeg";
import drAmrImg from "../../../assets/imgs/Dr Amr Elkamah test/1.jpeg";
import DrHishamImg from "../../../assets/imgs/Dr Hisham kotb test/7.jpeg";
import DrHossamElmalahImg from "../../../assets/imgs/Dr Hossam Elmalah test/1.jpeg";
import DrkhaledElkordyImg from "../../../assets/imgs/Dr khaled Elkordy test/1.jpeg";
import DrMagdaMouradImg from "../../../assets/imgs/Dr Magda Mourad test/1.jpeg";
import DrMahmoudMahgoubImg from "../../../assets/imgs/Dr Mahmoud Mahgoub test/1.jpeg";
import DrOmarElsadatImg from "../../../assets/imgs/Dr Omar Elsadat test/1.jpeg";
import DrOsamaElshahawyImg from "../../../assets/imgs/Dr Osama Elshahawy test/1.jpeg";
import DrRaniaNasrImg from "../../../assets/imgs/Dr Rania Nasr test/1.jpeg";
import DrWeaamGhoneamImg from "../../../assets/imgs/Dr Weaam Ghoneam test/1.jpeg";
import LaserLabAinShamsImg from "../../../assets/imgs/Laser Lab Ain Shams test/1.jpeg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Gallery = () => {
  const [t, i18n] = useTranslation();

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={classes.galleryContainer}>
      <h2> {t("gallary")} </h2>
      <Slider {...settings}>
        <div className={classes.imgContainer}>
          <div className={classes.linkContainer}>
            <Link to="/cleopatra">
              <BsImageFill className={classes.icon} />
            </Link>
          </div>
          <img src={cleopatraImg} alt="" />
        </div>
        <div className={classes.imgContainer}>
          <div className={classes.linkContainer}>
            <Link to="/drAhmedEssam">
              <BsImageFill className={classes.icon} />
            </Link>
          </div>
          <img src={DrAhmedEssamImg} alt="" />
        </div>
        <div className={classes.imgContainer}>
          <div className={classes.linkContainer}>
            <Link to="/drAmrElkamah">
              <BsImageFill className={classes.icon} />
            </Link>
          </div>
          <img src={drAmrImg} alt="" />
        </div>
        <div className={classes.imgContainer}>
          <div className={classes.linkContainer}>
            <Link to="/drHishamkotb">
              <BsImageFill className={classes.icon} />
            </Link>
          </div>
          <img src={DrHishamImg} alt="" />
        </div>
        <div className={classes.imgContainer}>
          <div className={classes.linkContainer}>
            <Link to="/drHossamElmalah">
              <BsImageFill className={classes.icon} />
            </Link>
          </div>
          <img src={DrHossamElmalahImg} alt="" />
        </div>
        <div className={classes.imgContainer}>
          <div className={classes.linkContainer}>
            <Link to="/drkhaledElkordy">
              <BsImageFill className={classes.icon} />
            </Link>
          </div>
          <img src={DrkhaledElkordyImg} alt="" />
        </div>
        <div className={classes.imgContainer}>
          <div className={classes.linkContainer}>
            <Link to="/drMagdaMourad">
              <BsImageFill className={classes.icon} />
            </Link>
          </div>
          <img src={DrMagdaMouradImg} alt="" />
        </div>
        <div className={classes.imgContainer}>
          <div className={classes.linkContainer}>
            <Link to="/drMahmoudMahgoub">
              <BsImageFill className={classes.icon} />
            </Link>
          </div>
          <img src={DrMahmoudMahgoubImg} alt="" />
        </div>
        <div className={classes.imgContainer}>
          <div className={classes.linkContainer}>
            <Link to="/drOmarElsadat">
              <BsImageFill className={classes.icon} />
            </Link>
          </div>
          <img src={DrOmarElsadatImg} alt="" />
        </div>
        <div className={classes.imgContainer}>
          <div className={classes.linkContainer}>
            <Link to="/drOsamaElshahawy">
              <BsImageFill className={classes.icon} />
            </Link>
          </div>
          <img src={DrOsamaElshahawyImg} alt="" />
        </div>
        <div className={classes.imgContainer}>
          <div className={classes.linkContainer}>
            <Link to="/drRaniaNasr">
              <BsImageFill className={classes.icon} />
            </Link>
          </div>
          <img src={DrRaniaNasrImg} alt="" />
        </div>
        <div className={classes.imgContainer}>
          <div className={classes.linkContainer}>
            <Link to="/drWeaamGhoneam">
              <BsImageFill className={classes.icon} />
            </Link>
          </div>
          <img src={DrWeaamGhoneamImg} alt="" />
        </div>
        <div className={classes.imgContainer}>
          <div className={classes.linkContainer}>
            <Link to="/laserLabAinShams">
              <BsImageFill className={classes.icon} />
            </Link>
          </div>
          <img src={LaserLabAinShamsImg} alt="" />
        </div>
      </Slider>
    </div>
  );
};
export default Gallery;
