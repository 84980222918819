import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Cleopatra from "./components/sliders/cleopatra/Cleopatra";
import ContactUs from "./components/contactUs/ContactUs";
import DrAhmedEssam from "./components/sliders/DrAhmedEssam/DrAhmedEssam";
import DrAmrElkamah from "./components/sliders/DrAmrElkamah/DrAmrElkamah";
import DrHishamkotb from "./components/sliders/drHishamkotb/DrHishamkotb";
import DrHossamElmalah from "./components/sliders/DrHossamElmalah/DrHossamElmalah";
import DrkhaledElkordy from "./components/sliders/DrkhaledElkordy/DrkhaledElkordy";
import DrMagdaMourad from "./components/sliders/DrMagdaMourad/DrMagdaMourad";
import DrMahmoudMahgoub from "./components/sliders/DrMahmoudMahgoub/DrMahmoudMahgoub";
import DrOmarElsadat from "./components/sliders/DrOmarElsadat/DrOmarElsadat";
import DrOsamaElshahawy from "./components/sliders/DrOsamaElshahawy/DrOsamaElshahawy";
import DrRaniaNasr from "./components/sliders/DrRaniaNasr/DrRaniaNasr";
import DrWeaamGhoneam from "./components/sliders/DrWeaamGhoneam/DrWeaamGhoneam";
import LaserLabAinShams from "./components/sliders/LaserLabAinShams/LaserLabAinShams";
import ClientsPage from "./pages/ClientsPage";
import GallaryPage from "./pages/GallaryPage";
import HomePage from "./pages/HomePage";
import AboutUsPage from "./pages/AboutUsPage";
import { Toaster } from "react-hot-toast";

const App = () => {
  const [contactModal, setContactModal] = useState(false);
  const [contactModal2, setContactModal2] = useState(false);

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={<HomePage setContactModal2={setContactModal2} />}
          />
          <Route
            path="/Clients"
            element={<ClientsPage setContactModal2={setContactModal2} />}
          />
          <Route
            path="/gallery"
            element={<GallaryPage setContactModal2={setContactModal2} />}
          />
          <Route
            path="/aboutus"
            element={<AboutUsPage setContactModal2={setContactModal2} />}
          />
          <Route path="/cleopatra" element={<Cleopatra />} />
          <Route path="/drAhmedEssam" element={<DrAhmedEssam />} />
          <Route path="/drAmrElkamah" element={<DrAmrElkamah />} />
          <Route path="/drHishamkotb" element={<DrHishamkotb />} />
          <Route path="/drHossamElmalah" element={<DrHossamElmalah />} />
          <Route path="/drkhaledElkordy" element={<DrkhaledElkordy />} />
          <Route path="/drMagdaMourad" element={<DrMagdaMourad />} />
          <Route path="/drMahmoudMahgoub" element={<DrMahmoudMahgoub />} />
          <Route path="/drOmarElsadat" element={<DrOmarElsadat />} />
          <Route path="/drOsamaElshahawy" element={<DrOsamaElshahawy />} />
          <Route path="/drRaniaNasr" element={<DrRaniaNasr />} />
          <Route path="/drWeaamGhoneam" element={<DrWeaamGhoneam />} />
          <Route path="/laserLabAinShams" element={<LaserLabAinShams />} />
        </Routes>
        <ContactUs
          setContactModal2={setContactModal2}
          contactModal2={contactModal2}
          setContactModal={setContactModal}
          contactModal={contactModal}
        />
      </Router>
      <Toaster
        position="bottom-right"
        toastOptions={{
          style: {
            fontSize: "1rem",
          },
        }}
      />
    </>
  );
};

export default App;
