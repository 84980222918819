import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./gallary.module.css";

import cleopatraImg from "../../assets/imgs/Cleopatra test/1.jpeg";
import DrAhmedEssamImg from "../../assets/imgs/Dr Ahmed Essam test/1.jpeg";
import drAmrImg from "../../assets/imgs/Dr Amr Elkamah test/1.jpeg";
import DrHishamImg from "../../assets/imgs/Dr Hisham kotb test/7.jpeg";
import DrHossamElmalahImg from "../../assets/imgs/Dr Hossam Elmalah test/4.jpeg";
import DrkhaledElkordyImg from "../../assets/imgs/Dr khaled Elkordy test/1.jpeg";
import DrMagdaMouradImg from "../../assets/imgs/Dr Magda Mourad test/1.jpeg";
import DrMahmoudMahgoubImg from "../../assets/imgs/Dr Mahmoud Mahgoub test/1.jpeg";
import DrOmarElsadatImg from "../../assets/imgs/Dr Omar Elsadat test/1.jpeg";
import DrOsamaElshahawyImg from "../../assets/imgs/Dr Osama Elshahawy test/1.jpeg";
import DrRaniaNasrImg from "../../assets/imgs/Dr Rania Nasr test/1.jpeg";
import DrWeaamGhoneamImg from "../../assets/imgs/Dr Weaam Ghoneam test/1.jpeg";
import LaserLabAinShamsImg from "../../assets/imgs/Laser Lab Ain Shams test/1.jpeg";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Gallary = () => {
  const [t, i18n] = useTranslation();

  return (
    <div className={classes.bg}>
      <header className={classes.header}>
        <h1>{t("gallary")}</h1>
        <Container fluid>
          <Row>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <Link to="/drAhmedEssam">
                  <h6>Dr.Ahmed Essam</h6>
                  <img src={DrAhmedEssamImg} alt="" />
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <Link to="/drOmarElsadat">
                  <h6>Dr.Omar Elsadat</h6>
                  <img src={DrOmarElsadatImg} alt="" />
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <Link to="/drAmrElkamah">
                  <h6>Dr.Amr Elkamah</h6>
                  <img src={drAmrImg} alt="" />
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <Link to="/drHishamkotb">
                  <h6>Dr.Hisham kotb</h6>
                  <img src={DrHishamImg} alt="" />
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <Link to="/drHossamElmalah">
                  <h6>Dr.Hossam Elmalah</h6>
                  <img src={DrHossamElmalahImg} alt="" />
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <Link to="/drkhaledElkordy">
                  <h6>Dr.khaled Elkordy</h6>
                  <img src={DrkhaledElkordyImg} alt="" />
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <Link to="/drMagdaMourad">
                  <h6>Dr.Magda Mourad</h6>
                  <img src={DrMagdaMouradImg} alt="" />
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <Link to="/drMahmoudMahgoub">
                  <h6>Dr.Mahmoud Mahgoub</h6>
                  <img src={DrMahmoudMahgoubImg} alt="" />
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <Link to="/drOsamaElshahawy">
                  <h6>Dr.Osama Elshahawy</h6>
                  <img src={DrOsamaElshahawyImg} alt="" />
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <Link to="/drRaniaNasr">
                  <h6>Dr.Rania Nasr</h6>
                  <img src={DrRaniaNasrImg} alt="" />
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <Link to="/drWeaamGhoneam">
                  <h6>Dr.Weaam Ghoneam</h6>
                  <img src={DrWeaamGhoneamImg} alt="" />
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <Link to="/cleopatra">
                  <h6>Cleopatra</h6>
                  <img src={cleopatraImg} alt="" />
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <Link to="/laserLabAinShams">
                  <h6>Laser Lab Ain Shams</h6>
                  <img src={LaserLabAinShamsImg} alt="" />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </header>
    </div>
  );
};

export default Gallary;
